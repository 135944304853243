import React, { useState , useEffect , Fragment } from 'react';
import logo from '../src/Aset/Maharaja-Logo.png'
import bg from '../src/Aset/bg.jpg'
import bg1 from '../src/Aset/home.jpg'
import Marquee from 'react-fast-marquee';
import i18n, { init } from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next';
import butter from '../src/Aset/zupa.png'
import palak from '../src/Aset/platter.png'
import tikka from '../src/Aset/lassi.png'
import chana from '../src/Aset/chana.png'
import chai from '../src/Aset/chai.png'
import salat from '../src/Aset/somosa.png'
import extr from '../src/Aset/salata.png'
import CookieConsent, { Cookies } from "react-cookie-consent";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { bottom } from '@popperjs/core';
import {BrowserView, MobileView} from 'react-device-detect';
import uber from './Aset/uber.png'
import bolt from './Aset/pys.png'
import govo from './Aset/Govo.png'
import everest from './Aset/suhana.jpeg'
import trs from './Aset/pran.png'
import mdh from './Aset/priva.jpeg'
import catch1 from './Aset/shan.png'
import durra from './Aset/patak.png'
import patak from './Aset/durra.jpeg'
import shan from './Aset/catch.png'
import priya from './Aset/mdh.jpeg'
import pran from './Aset/trs.png'
import suhana from './Aset/everest.png'



i18n.use(initReactI18next)
.use(LanguageDetector)
.use(HttpApi)
init({

  fallbackLng:'en', 
  detection:{
    order: ['cookie','htmlTag','localStorage','path', 'subdomain'],
    caches:['cookie'],
  },
backend: {
  loadPath: 'assets/locales/{{lng}}/translation.json',
},
react: {useSuspense: false},
}
)

const languages = [
  {
    code :'en',
    name:'English',
    country_code:'gb'
  },
  {
    code :'pl',
    name:'Polska',
    country_code:'pl'
  }
]




const Header = () => {

  const {t} = useTranslation();
  const [active, setActive] = useState(false);

  

  
 
    return (

      
        //<div className="">
        
         <div className="">
          {active && <div className="modal show bd-example-modal-lg" tabindex="-1" role="dialog">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" onClick={() => setActive(false)} className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <iframe src="https://www.restaurantlogin.com/api/fb/3_ejjz" width="750" height="500" />         
                </div>
                <div className="modal-footer">
                  <button onClick={() => setActive(false)} type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>}

        <main role="main" className="m-layout__content">
          <section className="m-section m-section--hero">
            <h2 className="sr-only">We recommend</h2>
            <div className="m-hero ">
              <div className="m-hero__cover-wrapper m-hero__cover-wrapper--sticky">
                <div className="m-hero__bg-img">
                  <img
                    className="m-hero__cover-item"
                    srcset={bg1} // background
                    sizes="100vw"
                    src={bg1} //background
                    alt=""
                  />
                </div>
              </div>

              <div className="m-hero__content mx-auto my-auto ">
                
              <div className='text-center'>
               
                  <div className='roller'>
                  <h2> {t('offer')} </h2>
                  </div>                 
          
                <div>
                </div>
              </div>
              </div>
              
              <div className="m-hero__content  mx-auto my-auto ">
                
              <div classname="dropdown ">
                          <button class="btn btn-primary  dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          En/Pl
                          </button>
                          <ul class="dropdown-menu "  aria-labelledby="dropdownMenuButton1">
                            {languages.map(({ code, name ,country_code}) => (
                            <li key={country_code}><a classname="dropdown-item " onClick={() => i18next.changeLanguage(code) } >{name}</a></li>
                            ))}
                         </ul>
                        </div>

                <div className="m-slider js-slider ">
                  <div
                    className="m-slider__list js-slider__list"
                    id="hero-slider"
                    data-slick='{"asNavFor": "#", "fade": false, "autoplay": false, "autoplaySpeed": 5000}'
                  >
                    <article className="m-slider__list-item">
                      <div className=" flex">
                     
                     
                        <div className="m-slider__slide-content u-text-center mx-auto flex  ">
                         
                         
                        
                       
                       <img src={logo} width="25%" alt="logo"  className='center'/>
                      
                        <h1 className="m-slider__title m-page-header">
                            <span className="m-page-header__title">
                              Maharaja
                            </span>
                            </h1> 

                          <p className="m-slider__description u-my4">
                          {t('title_1')}
                         </p>
                         <BrowserView>
                          <p className="m-slider__action u-my4">
                          <button onClick={(e) => setActive(true)}
                              className="btn btn-lg btn-primary m-hero__btn">
                            {t('menu')}
                        </button>                           
                          </p>
                          </BrowserView>
                          <MobileView>
                          <button type="button" class="btn btn-primary" onClick={(e) => { e.preventDefault(); window.location.href='https://www.restaurantlogin.com/api/fb/3_ejjz'; }} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                            Menu
                        </button>
                          </MobileView>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>

              <aside className="m-hero__info-boxes mx-auto align-self-end">
                <div className="m-info-boxes">
                  <div
                    className="m-info-boxes__list"
                    data-restaurant-visible="maharaja"
                  >
                    <article className="bbg-b ">
                      <h4 className="m-info-boxes__title ">{t('Open_Hours')}</h4>

                      <div className="m-info-boxes__description">
                        <div
                          className="m-restaurant-hours m-restaurant-hours--parent-align js-restaurant-hours js-restaurant-hours-opening"
                          data-restaurant-slug="Maharaja"
                          data-active-classes=""
                          data-inactive-classes=""
                        >
                          <div className="m-restaurant-hours__item m-restaurant-hours__item--singular js-restaurant-hours-present-day">
                            <span className="m-restaurant-hours__header js-restaurant-hours-header">
                            {t('Monday_Friday')} &nbsp;
                            </span>
                            <span className="m-restaurant-hours__data-wrapper">
                              <span className="m-restaurant-hours__data js-restaurant-hours-data"></span>
                            <p> 11:00 - 21:00 </p>
                            </span>
                            <span className="clearfix"></span>
                            <span className="m-restaurant-hours__header js-restaurant-hours-header">
                            {t('Sunday')} &nbsp;
                            </span>
                            <span className="m-restaurant-hours__data-wrapper">
                              <span className="m-restaurant-hours__data js-restaurant-hours-data"></span>
                            <p> 12:00 - 20:00 </p>
                            </span>
                            <span className="clearfix"></span>
                          </div>

                         
                          <div
                            className="js-restaurant-hours-popover"
                            style={{"display": "none"}}
                            id="opening-hours-popover-Maharaja-1665046614121668666000"
                          >
                            <strong className="pull-left js-restaurant-hours-popover-title"></strong>
                            <div className="pull-right m-popover__dismiss">
                              <button
                                type="button"
                                className="close js-dismiss-popover"
                              >
                                ×
                              </button>
                            </div>
                            <div className="clearfix"></div>
                            <div className="js-restaurant-hours-popover-details"></div>
                            <div className="js-restaurant-hours-popover-info"></div>
                          </div>
                        </div>
                      </div>
                    </article>
                    
                    <article className="bbg-b">
                      <h4 className="m-info-boxes__title">{t('Contact')}</h4>

                      <div className="m-info-boxes__description">
                        511 365 829
                        <br />
                        <a
                          href="mailto:kontact@maharajarestaurant.pl"
                          className="u-link-unstyled underline-decoration"
                        >
                          indianwroclaw@gmail.com
                        </a>
                      </div>
                    </article>

                    <article className="bbg-b ">
                      <h4 className="m-info-boxes__title">{t('Available_on')}</h4>

                      <div className="m-info-boxes__description row" >
                        <img src={uber} width="100"  alt="glovo" className='cent' />
                        <br />
                        <img src={govo} alt="glovo" className='cent'/>
                         <br />
                         <img src={bolt}  alt="glovo" className='cent'/>
                         <br />
                      </div>
                    </article>
                  </div>
                </div>
              </aside>
            </div>
          </section>

          <section className="m-section" id="section-menu">
            <div className="m-container">
              <div className="">
                <header className="m-page-subheader">
                  <h2 className="m-page-subheader__heading">
                    <span className="m-page-subheader__title">Menu</span>
                  </h2>
                  <p className="m-page-subheader__description">
                  {t('title_m')}
                  <br />
                  {t('title2_m')}
                  </p>
                </header>
                
                <div className='col text-center  JCC2'>   
                <BrowserView>
                          <p className="m-slider__action u-my4">
                          <button onClick={() => setActive(true) } // setActive(true)
                              className="btn btn-lg btn-primary m-hero__btn">
                            {t('order_online')}
                        </button>                           
                          </p>
                          </BrowserView>
                          <MobileView>
                          <button type="button" class="btn btn-primary" onClick={(e) => { e.preventDefault(); window.location.href='https://www.restaurantlogin.com/api/fb/3_ejjz'; }} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                            See menu & Order online
                        </button>
                          </MobileView>
                 </div> 

              </div>
            </div>
          </section>

          <section className="m-section" id="section-articles">
            <div className="m-container shadow" style={{ borderRadius:'40px', border: '5px solid rgba(255, 255, 255, 0.5)'}}>
              <div className="">
                <div className="m-articles m-articles--vertical ">
                  <div className="container-fluid">
                    <header className="m-page-subheader m-page-subheader--center ">
                      <h2 className="m-page-subheader__heading">
                        <span className="m-page-subheader__title">
                        {t('Today_we_recommend')}
                        </span>
                      </h2>

                      <p className="m-page-subheader__description"></p>
                    </header>

                    <div>
                      <div
                        className="m-articles__list m-articles__list--vertical  "
                        data-responsive='{"xsmall": 1, "small": 2, "medium": 3}'
                      >
                        <article className="m-articles__item m-articles__item--vertical ">
                          <p className="m-articles__media">
                            <img
                              className="m-articles__img"
                              src={butter}
                              width="350"
                               alt=""
                              loading="lazy"
                            />
                          </p>

                          <div>
                            <header className="m-articles__header d-flex flex-column-reverse">
                              <h3 className="m-heading m-articles__title u-base-font-size u-mt2 u-mb0">
                              {t('Menu1')}
                              </h3>
                            </header>

                            <p className="m-articles__content">
                            {t('Menu1_t')}
                            </p>

                            <p className="m-articles__action u-mb0 u-text-center">
                              <a
                                href="/#section-menu"
                                className="btn btn-primary   m-articles__btn-special"
                              >
                                Try Now
                              </a>
                            </p>
                          </div>
                        </article>

                        <article className="m-articles__item m-articles__item--vertical ">
                          <p className="m-articles__media">
                            <img
                              className="m-articles__img"
                              src={tikka}
                              width="350"
                               alt=""
                              loading="lazy"
                            />
                          </p>

                          <div>
                            <header className="m-articles__header d-flex flex-column-reverse">
                              <h3 className="m-heading m-articles__title u-base-font-size u-mt2 u-mb0">
                              {t('Menu2')}
                              </h3>
                            </header>

                            <p className="m-articles__content">
                            {t('Menu2_t')}
                            </p>

                            <p className="m-articles__action u-mb0 u-text-center">
                              <a
                                href="/#section-menu"
                                className="btn btn-primary   m-articles__btn-special"
                              >
                                Try Now
                              </a>
                            </p>
                          </div>
                        </article>

                        <article className="m-articles__item m-articles__item--vertical ">
                          <p className="m-articles__media">
                            <img
                              className="m-articles__img"
                              src={palak}
                              width="350"
                              alt=""
                              loading="lazy"
                            />
                          </p>

                          <div>
                            <header className="m-articles__header d-flex flex-column-reverse">
                              <h3 className="m-heading m-articles__title u-base-font-size u-mt2 u-mb0">
                              {t('Menu3')}
                              </h3>
                            </header>

                            <p className="m-articles__content">
                            {t('Menu3_t')}
                             </p>

                            <p className="m-articles__action u-mb0 u-text-center">
                              <a
                                href="/#section-menu"
                                className="btn btn-primary   m-articles__btn-special"
                              >
                                Try Now
                              </a>
                            </p>
                          </div>
                          
                        </article>
                        <article className="m-articles__item m-articles__item--vertical ">
                          <p className="m-articles__media">
                            <img
                              className="m-articles__img"
                              src={chana}
                              width="350"
                              alt=""
                              loading="lazy"
                            />
                          </p>

                          <div>
                            <header className="m-articles__header d-flex flex-column-reverse">
                              <h3 className="m-heading m-articles__title u-base-font-size u-mt2 u-mb0">
                              {t('Menu7')}
                              </h3>
                            </header>

                            <p className="m-articles__content">
                            {t('Menu7_t')}
                             </p>

                            <p className="m-articles__action u-mb0 u-text-center">
                              <a
                                href="/#section-menu"
                                className="btn btn-primary   m-articles__btn-special"
                              >
                                Try Now
                              </a>
                            </p>
                          </div>
                          
                        </article>
                        <article className="m-articles__item m-articles__item--vertical ">
                          <p className="m-articles__media">
                            <img
                              className="m-articles__img"
                              src={chai}
                              width="350"
                              alt=""
                              loading="lazy"
                            />
                          </p>

                          <div>
                            <header className="m-articles__header d-flex flex-column-reverse">
                              <h3 className="m-heading m-articles__title u-base-font-size u-mt2 u-mb0">
                              {t('Menu4')}
                              </h3>
                            </header>

                            <p className="m-articles__content">
                            {t('Menu4_t')}
                             </p>

                            <p className="m-articles__action u-mb0 u-text-center">
                              <a
                                href="/#section-menu"
                                className="btn btn-primary   m-articles__btn-special"
                              >
                                Try Now
                              </a>
                            </p>
                          </div>
                          
                        </article>
                        <article className="m-articles__item m-articles__item--vertical ">
                          <p className="m-articles__media">
                            <img
                              className="m-articles__img"
                              src={salat}
                              width="350"
                              alt=""
                              loading="lazy"
                            />
                          </p>

                          <div>
                            <header className="m-articles__header d-flex flex-column-reverse">
                              <h3 className="m-heading m-articles__title u-base-font-size u-mt2 u-mb0">
                              {t('Menu5')}
                              </h3>
                            </header>

                            <p className="m-articles__content">
                            {t('Menu5_t')}
                             </p>

                            <p className="m-articles__action u-mb0 u-text-center">
                              <a
                                href="/#section-menu"
                                className="btn btn-primary   m-articles__btn-special"
                              >
                                Try Now
                              </a>
                            </p>
                          </div>
                          
                        </article>
                        <article className="m-articles__item m-articles__item--vertical ">
                          <p className="m-articles__media">
                            <img
                              className="m-articles__img"
                              src={extr}
                              width="350"
                              alt=""
                              loading="lazy"
                            />
                          </p>

                          <div>
                            <header className="m-articles__header d-flex flex-column-reverse">
                              <h3 className="m-heading m-articles__title u-base-font-size u-mt2 u-mb0">
                              {t('Menu6')}
                              </h3>
                            </header>

                            <p className="m-articles__content">
                            {t('Menu6_t')}
                             </p>

                            <p className="m-articles__action u-mb0 u-text-center">
                              <a
                                href="/#section-menu"
                                className="btn btn-primary   m-articles__btn-special"
                              >
                                Try Now
                              </a>
                            </p>
                          </div>
                          
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="m-section" id="section-reviews">
            <div className="m-container">
              <div className="">
                <header className="m-page-subheader u-text-center">
                  <h2 className="m-page-subheader__title">{t('Client_Opnion')}</h2>

                  <p className="m-page-subheader__description">
                  {t('Return_on_Relationships')}
                  </p>
                </header>

                <div className="m-testimonials m-testimonials--boxes  ">
                  <div
                    className="m-testimonials__list m-testimonials__list--boxes "
                    data-responsive='{"xsmall": 1, "small": 3}'
                  >
                    <article className="m-testimonials__item m-testimonials__item--boxes">
                      <p className="m-testimonials__rating">
                        <span className="stars" title="5/5">
                          <i className="icon-star"></i>
                          <i className="icon-star"></i>
                          <i className="icon-star"></i>
                          <i className="icon-star"></i>
                          <i className="icon-star"></i>
                        </span>
                      </p>

                      <p className="m-testimonials__desc">Food was 5* - tasty and well prepared.
Prices 5* - good for the amount you get.
Waiting time was reasonable. I waited around 10 for food.
The place itself (interior design) 4* - clean and tidy, but necessary refreshment is noticeable. Area is very small, so it's not the best place to come with entire family.</p>

                      <footer className="m-testimonials__item-footer">
                        <span className="m-testimonials__author">Grzegorz Tymiński</span>
                      </footer>
                    </article>

                    <article className="m-testimonials__item m-testimonials__item--boxes">
                      <p className="m-testimonials__rating">
                        <span className="stars" title="5/5">
                          <i className="icon-star"></i>
                          <i className="icon-star"></i>
                          <i className="icon-star"></i>
                          <i className="icon-star"></i>
                          <i className="icon-star"></i>
                        </span>
                      </p>

                      <p className="m-testimonials__desc">
                      I like the food, it's also pocket friendly. The food cooked here is like normal Ghar ka Khana.. so you can literally eat everyday.
                      </p>

                      <footer className="m-testimonials__item-footer">
                        <span className="m-testimonials__author">Swati Ojha</span>
                      </footer>
                    </article>

                    <article className="m-testimonials__item m-testimonials__item--boxes">
                      <p className="m-testimonials__rating">
                        <span className="stars" title="5/5">
                          <i className="icon-star"></i>
                          <i className="icon-star"></i>
                          <i className="icon-star"></i>
                          <i className="icon-star"></i>
                          <i className="icon-star"></i>
                        </span>
                      </p>

                      <p className="m-testimonials__desc">
                      Very delicious food. Especially rise is always cooked very well . Excellent service . Suggest to visit everyone
                      </p>

                      <footer className="m-testimonials__item-footer">
                        <span className="m-testimonials__author">Rasul Jacobs</span>
                      </footer>
                    </article>
                  </div>
                </div>

                <p className="u-text-center u-base-spacing-t">
                  <a href="https://www.google.com/search?gs_ssp=eJzj4tVP1zc0zC0ryjWyKCw3YLRSNagwMTdISzZOs0y2TEwxM04ytjKoMLVINUk0Mk80TjZJSUlMMfESyU3MSCxKzEpUSCwqUchPU0jLz08BAF_jF3U&q=maharaja+art+of+food&rlz=1C5CHFA_enPL907PL907&oq=maharaja&aqs=chrome.2.69i59l2j46i39i175i199j46i175i199i512j0i512j46i512l2j69i61.5567j0j4&sourceid=chrome&ie=UTF-8#lrd=0x470fc3f9c9ad63b3:0x58e4a27a3c4ddad4,3,,," className="btn btn-primary">
                  See all reviews
                  </a>
                </p>
              </div>
            </div>
          </section>

          <section className="m-section" id="section-delivery">
            <div className="m-container">
              <div className="">
                <header className="m-page-subheader u-text-center">
                  <h2 className="m-page-subheader__title">Partners</h2>
                </header>
                <div className="js-delivery-module">
                  <div className="form-wrapper">
                  <div className='text-center '>
                <Marquee  >
                  <img src={everest} width="300" alt="bg" style={{marginLeft: 10}}/> 
                  <img src={trs} width="300" alt="bg"style={{marginLeft: 10}}/> 
                  <img src={catch1} width="300" alt="bg"style={{marginLeft: 10}}/> 
                  <img src={mdh} width="300" alt="bg"style={{marginLeft: 10}}/> 
                  <img src={durra} width="300" alt="bg"style={{marginLeft: 10}}/> 
                  <img src={patak} width="300" alt="bg"style={{marginLeft: 10}}/> 
                  <img src={shan} width="300" alt="bg"style={{marginLeft: 10}}/> 
                  <img src={priya} width="300" alt="bg"style={{marginLeft: 10}}/> 
                  <img src={pran} width="300" alt="bg"style={{marginLeft: 10}}/> 
                  <img src={suhana} width="300" alt="bg"style={{marginLeft: 10}}/> 
                </Marquee>
                <div>
                </div>
              </div>
                    <form className="m-form m-delivery-address form-centered js-delivery-form hide">
                      <h4 className="text-center">
                        {" "}
                        Sprawdź czy dowozimy na Twój adres:
                      </h4>
                      <p></p>

                      <div className="js-delivery-form-placeholder"></div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="button js-delivery-form-submit"
                        >
                          Sprawdź
                        </button>
                      </div>
                    </form>
                  </div>

                  <div className="alert alert-danger hide js-geocoding-errors"></div>

                  <div className="alert alert-danger hide js-delivery-details--error">
                    <i className="icon-meh"></i>Niestety{" "}
                    <strong>nie dowozimy</strong> na podany adres. <br /> W
                    razie wątpliwości (błąd geolokacji) zadzwoń, aby się
                    upewnić:
                    <ul className="list">
                      <li>
                        <strong>maharaja - 537 534 886</strong>
                      </li>
                    </ul>
                  </div>

                  <div
                    className="alert alert-success hide js-delivery-details--ok"
                    data-restaurant="167760"
                  >
                    <h3 className="text-center">
                      <i className="icon-ok icon-green"></i> Dowozimy na Twój adres
                    </h3>
                    <br />
                    
                    <div className="col-md-6">
                      <p className="opening-hours__heading highlight">
                        <strong>Informacje o dostawie:</strong>
                      </p>
                      <ul className="list-unstyled">
                        <li>
                          Koszt dostawy:{" "}
                          <strong className="js-delivery-details__delivery-cost"></strong>
                        </li>
                        <li>
                          Minimalna wartość zamówenia:{" "}
                          <strong className="js-delivery-details__min-order-value"></strong>
                        </li>
                        <li>
                          Dostawa darmowa od:{" "}
                          <strong className="js-delivery-details__free-threshold"></strong>
                        </li>
                        <li>
                          Kierowca ma przy sobie max: <strong>20 zł</strong>
                        </li>
                        <li>
                          Płatność:
                          <strong>
                            Gotówka, Szybki przelew, Karta przy odbiorze
                          </strong>
                        </li>
                      </ul>

                      <div className="text-center">
                        <a
                          href="#"
                          className="button delivery-button text-center js-delivery-details__button"
                        >
                          {t('order_z')}
                        </a>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        

          <section className="m-section">
            <div className="m-container">
              <div className="bordermap">
                <section className="m-section  " id="section-contact">
                  <header className="m-page-subheader  ">
                    <h2 className="m-page-subheader__heading">
                      <span className="m-page-subheader__title">
                      {t('Contact')}
                      </span>
                    </h2>
                    <p className="m-page-subheader__description">
                    {t('Write_us')}
                    </p>
                  </header>

                  <div className="m-map m-map--one-page-section ">
                    <iframe
                      className="m-map__embed"
                      src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBMgIqPT35X3AXg-SwS0FVdZjwbgN4SRRI&amp;q=Maharaja+Indian+Restaurant+(+Art+of+food+)!4m6!3m5!1s0x470fc3f9c9ad63b3:0x58e4a27a3c4ddad4!8m2!3d51.1008516!4d17.0363539!16s%2Fg%2F11mvrm28qw"
                      loading="lazy"
                    ></iframe>
                  </div>
                </section>
              </div>
            </div>
          </section>

          <section className="m-section m-section--footer">
            <div className="m-container">
              <div className="borderimg"> {/* box */}
                <footer className="m-footer m-footer--variant-1">
                  <div className="m-footer__content">
                    <div className="container-fluid">
                      <div className="row row--flex">
                        <div className="col"></div>
                      </div>

                      <div className="" data-restaurant-visible="Maharajamart.wroclaw@gmail.con">
                        <div className="row row--flex u-text-center">
                          <div className="col-12 col-md">
                            <h3 className="h4 u-small-spacing-y">{t('Address')}</h3>
                            <address className="u-base-spacing-b">
                              Maharaja
                              <br />
                              Kołłataja 22,
                              <br />
                              50-002 Wrocław
                            </address>
                          </div>

                          <div className="col-12 col-md">
                            <h3 className="h4 u-small-spacing-y">
                            {t('Contact')}
                            </h3>
                            <p className="u-base-spacing-b">
                              Adres email:{" "}
                              <a
                                href="mailto:511 365 829"
                                className="u-link-unstyled"
                              >
                                indianwroclaw@gmail.com
                              </a>
                              <br />
                              Telefon:
                              <a href="tel: 537-534-886" className="u-link-unstyled">
                              511 365 829
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="row row--flex">
                        <div className="col-12">
                          <div className="m-footer__socials">
                            <ul className="list-inline u-base-spacing-b">
                              <li className="">
                                <a
                                  href="#"
                                  title="Facebook"
                                  className=""
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <span
                                    className="icon-facebook"
                                    aria-hidden="true"
                                  ></span>
                                </a>
                              </li>

                              <li className="">
                               
                              </li>

                              <li className="">
                                <a
                                  href="#"
                                  title="Instagram"
                                  className=""
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <span
                                    className="icon-instagram"
                                    aria-hidden="true"
                                  ></span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="col-12">
                          <p className="u-small-spacing-b">
                            <a href="/#section-menu" className="btn btn-primary">
                            {t('order_online_m')}
                            </a>
                          </p>
                        </div>

                        <div className="col-12">
                          <div className="u-hidden-app">
                            <p className="m-footer__app-badges">
                            
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="row row--flex">
                        <div className="col m-footer__info u-base-spacing-t">
                          <small className="m-footer-info flex-column">
                            <span className="m-footer-info__terms">
                              <a
                                href="https://www.foodbooking.com/api/legal?type=dpp&add_header=1&language=en&uid=d7a43896-d231-4bd4-b6c0-842e8dc707cf#cookie-policy"
                                className="m-footer-info__link"
                                rel="nofollow"
                              >
                                {t('Regulations')}
                              </a>
                              
      
                              |{" "}
                              <a
                                href="https://www.foodbooking.com/api/legal?type=dpp&add_header=1&language=en&uid=d7a43896-d231-4bd4-b6c0-842e8dc707cf#cookie-policy"
                                className="m-footer-info__link"
                                rel="nofollow"
                              >
                                {t('Privacy_Policy')}
                              </a>
                          
                            </span>

                            

                            <span className="m-footer-info__info powered-by u-base-spacing-t u-text-center">
                              <span >
                              <a
                                href="//www.scopewit.com"
                                className="m-footer-info__link"
                                rel="nofollow"
                              >
                                {t('copyright')}
                              </a>
                            
                              </span>
                              <a
                                href="//www.scopewit.com"
                                className="u-link-wrapper"
                                target="_parent"
                               
                               
                              >
                                &nbsp;
                               {/* <svg
                                  width="130"
                                  height="26"
                                  className="svg-icon-restaumatic u-va-middle"
                                >
                                  <use xlinkHref="#svg-icon-restaumatic" />
                        </svg>*/}
                              </a>
                            </span>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </section>
        </main>
        <CookieConsent debug='true' style={{background:'#ADC1CC', borderRadius:'30px'}} buttonStyle={{background:'#FAD969', borderRadius:'30px'}}>Maharaja Sp. z o.o. z siedzibą w Wroclaw jako administrator danych osobowych przetwarza Twoje dane osobowe, stosując pliki cookie w celu zapewnienia prawidłowego funkcjonowania serwisu. Może również razem z partnerami, po wyrażeniu przez Ciebie zgody, wykorzystywać pliki cookie w celach analitycznych i marketingowych, w szczególności dopasowania treści reklamowych do Twoich preferencji. Klikając „Akceptuj wszystkie” lub „Akceptuj wymagane”, wyrażoną zgodę możesz wycofać w każdym momencie, zmieniając wybrane ustawienia. Więcej informacji o korzystaniu z plików cookie oraz o przetwarzaniu Twoich danych osobowych, w tym o przysługujących Ci uprawnieniach, znajdziesz w naszej Polityce Prywatności, a ustawienia Cookies możesz dostosować tutaj Ustawienia.</CookieConsent>
      </div>
    );
};

export default Header;